module.exports = [{
      plugin: require('/Users/cherripaul/Documents/www.paultsai.com/node_modules/gatsby-plugin-canonical-urls/gatsby-browser'),
      options: {"plugins":[],"siteUrl":"https://www.paultsai.com"},
    },{
      plugin: require('/Users/cherripaul/Documents/www.paultsai.com/node_modules/gatsby-remark-autolink-headers/gatsby-browser'),
      options: {"plugins":[],"icon":false},
    },{
      plugin: require('/Users/cherripaul/Documents/www.paultsai.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-132827571-1"},
    },{
      plugin: require('/Users/cherripaul/Documents/www.paultsai.com/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/cherripaul/Documents/www.paultsai.com/node_modules/gatsby-plugin-typography/gatsby-browser'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/cherripaul/Documents/www.paultsai.com/node_modules/gatsby-plugin-twitter/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/cherripaul/Documents/www.paultsai.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
